import { render, staticRenderFns } from "./CreatorChannel.vue?vue&type=template&id=6e8cb5e5&scoped=true&"
import script from "./CreatorChannel.ts?vue&type=script&lang=ts&"
export * from "./CreatorChannel.ts?vue&type=script&lang=ts&"
import style0 from "./CreatorChannel.vue?vue&type=style&index=0&id=6e8cb5e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8cb5e5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\amano\\Desktop\\apps\\remdia\\frontend\\creator\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e8cb5e5')) {
      api.createRecord('6e8cb5e5', component.options)
    } else {
      api.reload('6e8cb5e5', component.options)
    }
    module.hot.accept("./CreatorChannel.vue?vue&type=template&id=6e8cb5e5&scoped=true&", function () {
      api.rerender('6e8cb5e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CreatorChannel/CreatorChannel.vue"
export default component.exports