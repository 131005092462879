// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";

@Component({
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
  }
  public jumpLink(pageName: string) {
    this.$router.push({
      name: pageName,
      params: {},
    });
  }
}
